import React from  'react';



const Card = ({name, email, id}) => {

	
	return (

		<div className="bg-light-green dib br4 ma3 grow bw6 shadow-2">
			<img src= {`https://robohash.org/${id}?200x200`}  alt="robot" />
			<div>
				<h2 className="tc">{name}</h2>
				<p className="tc">{email}</p>

			</div>

			
		</div>

		);
}




export default Card;